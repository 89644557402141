@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease-out;
    opacity: 1;

    &.closing {
        opacity: 0;
    }
}

.modal-content {
    background-color: white;
    border-radius: 5px;
    min-width: 300px;
    min-height: 200px;
    z-index: 1001;
    position: relative;
    animation: fadeInScale 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    opacity: 1;

    &.closing {
        opacity: 0;
    }

    &>button {
        position: absolute;
        top: 18px;
        right: 8px;
        background-color: white;
        padding: 0px;
        margin: 0px;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        z-index: 3;
        border: none;
    }
}


.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

.closeButton {
    width: 20px;
    height: 20px;
    z-index: 3;
}