.canvasHidden {
    display: none;
}

.container {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.picContainer {
    position: relative;
    height: 50%;
    width: 50%;
    margin: 10px 0px;

    &>canvas {
        width: 100%;
        height: 90%;
        object-fit: cover;
        padding: 4px;
        position: relative;
        z-index: 2;
    }

    &>video {
        width: 100%;
        height: 90%;
        object-fit: cover;
        padding: 4px;
        position: relative;
        z-index: 2;
    }

    @media (max-width: 600px) {
        width: 80%;
    }
}

.picContainer::before {
    margin: 10px 0px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 4px solid transparent;
    background:
        repeating-linear-gradient(-45deg,
            #F6EAEE,
            #F6EAEE 2px,
            transparent 1px,
            transparent 5px);
    z-index: 1;
}