.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    position: relative;
}


.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 50px;
    padding-right: 40px;

    &>button {
        margin-left: 10px;
    }
}

.cancelButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 50px;

    &>button:nth-child(1) {
        background-color: white;
        border: 1px #E2C4CF solid;
        width: 170px;
        height: 50px;
    }

    &>button:nth-child(2) {
        width: fit-content;
        background-color: #A24450;
        color: white;
        height: 50px;
        width: 170px;
    }

}