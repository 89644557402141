.container {
    width: 100%;
    height: 100%;
}


.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &>div:nth-child(1) {
        margin-bottom: 20px;
    }
}


.checklist {
    color: #757575;
    font-size: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



    .list {
        width: 60%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0px;
    }

}


.checkbox {
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #F6EAEE;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        font-size: 18px;
        color: white;
        text-align: center;
        line-height: 25px;
        transition: all 0.3s ease;
    }

    &.checked:before {
        content: '✔';
        width: 25px;
        height: 25px;
    }
}