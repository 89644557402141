// .cameraWrapper {
//     position: relative;
//     width: 299px;
//     height: 299px;
//     overflow: hidden;
//     border-radius: 50%;
//     position: relative;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     &>video {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         object-fit: cover;
//     }
// }

.cameraWrapperOuter {

    position: relative;
    width: 350px;
    height: 350px;

    &>img {
        position: absolute;
        width: 120%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        pointer-events: none
    }
}


.cameraWrapper {
    position: relative;
    width: 299px;
    height: 299px;
    overflow: hidden;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .videoWrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        border-radius: 50%;

        .record {
            transform: translate(-50%, -50%) scaleX(-1);
        }

        video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scaleX(-1);
            object-fit: cover;
        }
    }
}


.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}