.container {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
}

.titleContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.holdPhone {
    width: 250px;
}


.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}