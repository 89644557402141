.container {
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.triangle {
    width: 18px;
    height: 18px;
    margin: 0px 10px;
    transform: translateY(-2px);
}

.title {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 5px auto;
    transform: translateX(-10px);
}



.stepsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    margin: auto;
    transform: translateX(10px);

    .steps {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        margin: 10px 0px;

        &>div:nth-child(1) {
            margin: 0px 5px;
        }
    }
}

.termsContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px auto;
    width: 70%;
    margin-top: 30px;
    color: #D9D9D9;

    & input {
        margin-top: 4px;
        margin-right: 6px;
        height: 18px;
        width: 18px;
    }
}