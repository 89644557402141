.container {
    border-radius: 50%;
    background-color: #F6EAEE;
    width: 20px;
    height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: #757575;
}