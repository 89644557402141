.container {
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
}

.imgContainer {
    width: 300px;
}

.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 40px;
}

.contentText {
    text-align: center;
}

.text {
    color: #D9D9D9;
}