.button {
    color: #757575;
    width: 120px;
    height: 40px;
    background-color: #E2C4CF;
    border: none;
    font-weight: 600;
    font-size: 18px;
    border-radius: 5px;
}

.disabled {
    background-color: #d8d8d8;
    cursor: not-allowed;
}