@font-face {
    font-family: 'Myriad-Variable-Concept';
    src: local('MyriadVariableConcept'), url(./fonts/MyriadVariableConcept-Roman.otf) format('otf');
}

@font-face {
    font-family: 'Baskerville-Old-Facef';
    src: local('Baskerville-Old-Facef'), url(./fonts/Baskerville-Old-Face-Regular.woff) format('woff');
}

a {
    color: grey;
}

a:hover{
    color: grey;
}

h1 {
    font-family: Baskerville-Old-Facef ,sans-serif ;
}

h2{
    font-family: MyriadVariableConcept , sans-serif ;
}

body::-webkit-scrollbar {
    display: none;
}

.element::-webkit-scrollbar {display:none}

/**** This is for home page bag slide container arrow style *****/
.slick-prev:before {
    /*  background-color: blue !important; */
    width: 50px;
    height: 50px;
    content: '' !important;
    position: absolute;
    top: -70px;
    left: -20px;
    background-image: url('https://assets.ohphire.com/bagSlideLeftArrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
}
    
.slick-next:before {
    /* background-color: blue !important; */
    width: 50px;
    height: 50px;
    content: '' !important;
    position: absolute;
    top: -70px;
    left: 10px;
    background-image: url('https://assets.ohphire.com/bagSlideRightArrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
}