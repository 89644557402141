.container {
    width: 100%;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.text {
    color: #D9D9D9;
    font-size: 18px;
    font-weight: 600;
}


.selectContent {
    width: 40%;
    border: 1px solid #D9D9D9;
    font-weight: 600;
    text-align: center;
    border-radius: 10px;
    margin: 40px;
}

.select {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6EAEE;

}