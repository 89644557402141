.canvasHidden {
    display: none;
}

.container {
    width: 100%;
    height: 100%;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;

    & img {
        width: 60%;
        height: 400px;
    }

    .textContent {
        transform: translateY(-60px);

        &>div:nth-child(1) {
            text-align: center;
        }

        &>div:nth-child(2) {
            color: #757575;
            font-size: 18px;
            font-weight: 600;
        }
    }
}